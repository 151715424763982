// @flow

import api, { apiGuestRequest, apiPublic } from '../../../api';
import * as constants from '../../../services/constants';
import { urlRegExp } from '../../../services/validations';
import notif from '../../Notif/services';
import i18n from '../../../i18n';
import qs from 'qs';

const region = constants.REGION;

export const pageSize = 2;

export const daysToGo = deadlineDate => {
  const daysToGoNumber = Math.round(
    (new Date(deadlineDate) - new Date()) / (1000 * 60 * 60 * 24)
  );
  return daysToGoNumber >= 0 ? daysToGoNumber : 0;
};

// US has an age restriction of at least 13 years for checkout.
// This returns the max date you can select so you are at least 12 years old
export const getRestrictedAge = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 12 * 13);
  return date;
};

export const getOccasionTypes = () => {
  return api({ url: `me/events/types` });
};

export const createEvent = event => {
  return api({
    method: 'post',
    url: `me/events`,
    data: event,
  });
};

export const deleteEvent = id => {
  return api({
    method: 'delete',
    url: `/me/events/${id}`,
  });
};

export const editEvent = event => {
  return api({
    method: 'patch',
    url: `me/events/${event.id}`,
    data: event,
  });
};

export const uploadImage = (image, eventId) => {
  const data = new FormData();
  data.append('file', image);

  return api({
    method: 'post',
    url: `me/events/${eventId}/image`,
    data: data,
  });
};

export const getUserEvents = (page, filter, sorting) => {
  const eventStatus =
    filter && filter.status && filter.status !== ''
      ? '&status=' + filter.status
      : '';
  const eventTypeIds =
    filter && filter.occasionId ? '&eventTypeIds=' + filter.occasionId : '';
  const eventSorting =
    sorting !== undefined && sorting.by !== '' && sorting.order !== ''
      ? '&sortBy=' + sorting.by + '&sortOrder=' + sorting.order
      : '';
  const url =
    '/me/events/details?pageIndex=' +
    page.index +
    '&pageSize=' +
    page.size +
    eventStatus +
    eventTypeIds +
    eventSorting;
  return api({ url: url });
};

export const getEventById = id => {
  return api({ url: `me/events/${id}` });
};

export const getEventDetails = id => api({ url: `me/events/${id}/details` });

export const getPublicEventByUuid = uuid => {
  return apiGuestRequest({ url: `events/${uuid}` });
};

export const inviteFriends = (eventUsers, eventId) => {
  return api({
    method: 'post',
    url: `me/events/${eventId}/invitees/invitations`,
    data: eventUsers,
  });
};

export const requestWishlist = (receiver, eventId) => {
  return api({
    method: 'post',
    url: `me/events/${eventId}/wishlists/invitations`,
    data: receiver,
  });
};

export const joinEvent = eventId => {
  return api({
    method: 'post',
    url: `me/events/${eventId}/invitee`,
  });
};

export const getInviteeStatus = eventId => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/invitee`,
  });
};

export const acceptJoinEvent = invitationUuid => {
  return api({
    method: 'post',
    url: `me/events/users/invitations/${invitationUuid}/accept`,
  });
};
export const getReceiverInvitation = invitationUuid => {
  return api({
    method: 'get',
    url: `me/receivers/invitations/${invitationUuid}`,
  });
};
export const acceptReceiverInvitation = invitationUuid => {
  return api({
    method: 'post',
    url: `me/receivers/invitations/${invitationUuid}/accept`,
  });
};

export const resolveReceiverInvitation = invitationUuid => {
  return new Promise((resolve, reject) => {
    getReceiverInvitation(invitationUuid)
      .then(result => {
        if (result && result.data) {
          const data = result.data;
          if (data.status !== 'receiver_accept') {
            acceptReceiverInvitation(invitationUuid)
              .then(() => {
                resolve(true);
              })
              .catch(e => {
                reject(e);
              });
          } else {
            resolve(true);
          }
        } else {
          reject(false);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const getRequestWishlist = eventId =>
  api({ url: `me/events/${eventId}/wishlists/invitations` });

export const getRequestWishlistStatus = async event => {
  const defVal = '';
  let wishlistRequestStatus = defVal;
  if (event && event.status === 'open') {
    wishlistRequestStatus = await getRequestWishlist(event.id).then(
      result => result.data.status || defVal
    );
  }
  return wishlistRequestStatus;
};

export const getNewEvent = event => {
  const newEvent = { ...event };
  newEvent.eventAccessType = getAccessTypeFormattedValue(event.eventAccessType);
  if (event.date === undefined || event.date === null) {
    newEvent.date = new Date();
  } else {
    newEvent.date = new Date(event.date);
    const d = new Date();
    newEvent.date.setHours(d.getHours(), d.getMinutes(), d.getSeconds());
  }
  if (event.amount !== undefined) {
    newEvent.amount =
      typeof event.amount === 'number'
        ? event.amount.toString()
        : event.amount.replace(/[.,]/g, '');
  } else {
    newEvent.amount = 0;
  }
  newEvent.eventTypeName = event.occasion.code;
  if (event.decideGiftLater === undefined) {
    newEvent.decideGiftLater = true;
  }
  newEvent.typeId =
    event.occasion.id === 'custom' ? null : parseInt(event.occasion.id, 10);
  delete newEvent.occasion;

  return newEvent;
};

const getAccessTypeFormattedValue = accessType =>
  accessType === true ? 'public' : 'private';

export const getTokenizationServerRequestData = (
  cardDetails,
  preRegistrationDataResponse
) => {
  const requestData = {
    accessKeyRef: preRegistrationDataResponse.accessKey,
    data: preRegistrationDataResponse.preregistrationData,
    cardNumber: cardDetails.creditCard.cardNumber.split(' ').join(''),
    cardExpirationDate: cardDetails.creditCard.expirationDate.replace('/', ''),
    cardCvx: cardDetails.creditCard.ccv
      ? cardDetails.creditCard.ccv.replace(' ', '')
      : null,
  };
  return requestData;
};

export const getPaymentDataWithSavedCard = (
  paymentData,
  currency,
  cardRegistrationId
) => {
  const newPaymentData = { ...paymentData };
  switch (currency) {
    case 'EUR':
      newPaymentData.amount = {
        value: parseFloat(
          paymentData.amount.replace(/\./g, '').replace(',', '.')
        ),
        currency: currency,
      };
      break;
    case 'USD':
      newPaymentData.amount = {
        value: parseFloat(paymentData.amount.replace(/,/g, '')),
        currency: currency,
      };
      break;
    default:
      newPaymentData.amount = {
        value: parseFloat(
          paymentData.amount.replace(/\./g, '').replace(',', '.')
        ),
        currency: currency,
      };
  }
  newPaymentData.type = 'credit_card';
  newPaymentData.clientType = 'web';
  if (paymentData.personalData) {
    newPaymentData.firstName = paymentData.personalData.firstName;
    newPaymentData.lastName = paymentData.personalData.lastName;
    newPaymentData.email = paymentData.personalData.email;
  }
  newPaymentData.visibility = paymentData.anonymous ? 'private' : 'public';
  newPaymentData.private = paymentData.anonymous ? true : false;

  newPaymentData.creditCard = {
    saveData:
      (paymentData.saveCard && paymentData.saveCard.save) ||
      paymentData.creditCard.saveData ||
      false,
      cardRegistrationId,
  };

  delete newPaymentData.personalData;
  newPaymentData['browserInfo'] = {
    javaEnabled: window.navigator.javaEnabled(),
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth === 30 ? 24 : window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    javascriptEnabled: 'true',
  };
  const ticketType = window.localStorage.getItem('ticketType');
  if (ticketType && ticketType !== 'undefined') {
    newPaymentData['ticketType'] = JSON.parse(ticketType);
  }
  return newPaymentData;
};

export const getPaymentData = (
  paymentData,
  currency,
  cardRegistrationData,
  cardRegistrationId
) => {
  const newPaymentData = { ...paymentData };
  switch (currency) {
    case 'EUR':
      newPaymentData.amount = {
        value: parseFloat(
          paymentData.amount.replace(/\./g, '').replace(',', '.')
        ),
        currency: currency,
      };
      break;
    case 'USD':
      newPaymentData.amount = {
        value: parseFloat(paymentData.amount.replace(/,/g, '')),
        currency: currency,
      };
      break;
    default:
      newPaymentData.amount = {
        value: parseFloat(
          paymentData.amount.replace(/\./g, '').replace(',', '.')
        ),
        currency: currency,
      };
  }
  newPaymentData.type = 'credit_card';
  newPaymentData.clientType = 'web';
  if (paymentData.personalData) {
    newPaymentData.firstName = paymentData.personalData.firstName;
    newPaymentData.lastName = paymentData.personalData.lastName;
    newPaymentData.email = paymentData.personalData.email;
  }
  newPaymentData.visibility = paymentData.anonymous ? 'private' : 'public';
  newPaymentData.private = paymentData.anonymous ? true : false;

  newPaymentData.creditCard = {
    cardRegistrationData,
    saveData:
      (paymentData.saveCard && paymentData.saveCard.save) ||
      paymentData.creditCard.saveData ||
      false,
    cardRegistrationId,
  };

  delete newPaymentData.personalData;
  newPaymentData['browserInfo'] = {
    javaEnabled: window.navigator.javaEnabled(),
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth === 30 ? 24 : window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    javascriptEnabled: 'true',
  };
  const ticketType = window.localStorage.getItem('ticketType');
  if (ticketType && ticketType !== 'undefined') {
    newPaymentData['ticketType'] = JSON.parse(ticketType);
  }
  return newPaymentData;
};

export const getIdealPaymentData = (paymentData, currency: string) => {
  const newPaymentData = { ...paymentData };
  newPaymentData.amount = {
    value: parseFloat(paymentData.amount.replace(/\./g, '').replace(',', '.')),
    currency: currency,
  };
  newPaymentData.visibility = paymentData.anonymous ? 'private' : 'public';
  newPaymentData.private = paymentData.anonymous ? true : false;
  newPaymentData.clientType = 'web';

  if (paymentData.personalData) {
    newPaymentData.firstName = paymentData.personalData.firstName;
    newPaymentData.lastName = paymentData.personalData.lastName;
    newPaymentData.email = paymentData.personalData.email;
  }
  delete newPaymentData.personalData;
  newPaymentData['browserInfo'] = {
    javaEnabled: window.navigator.javaEnabled(),
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth === 30 ? 24 : window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    javascriptEnabled: 'true',
  };

  const ticketType = window.localStorage.getItem('ticketType');
  if (ticketType && ticketType !== 'undefined') {
    newPaymentData['ticketType'] = JSON.parse(ticketType);
  }

  return newPaymentData;
};

export const getCashOutData = (profile, card, amount, client) => {
  const euStateCountries = ['US', 'CA', 'MX'];
  const ownerAddress = {
    addressLine1: profile.street1,
    addressLine2: profile.street2,
    city: profile.city,
    postalCode: profile.postalCode,
    countryCode: profile.countryField
      ? profile.countryField.value
      : profile.countryCode,
  };
  if (region === 'US') {
    ownerAddress.region = profile.region || profile.stateField.value;
  } else {
    ownerAddress.nationality = profile.nationality.value
      ? profile.nationality.value
      : profile.nationality;
    if (
      profile.countryField &&
      euStateCountries.includes(profile.countryField.value)
    ) {
      ownerAddress.region = profile.stateField.value;
    }
  }

  const birthDate = new Date(profile.birthDate);
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    birthDate: birthDate,
    ownerAddress: ownerAddress,
    bankDTO: {
      ...card,
      country: region === 'US' ? card.country.value || card.country : '',
      saveData: card.saveData && card.saveData.save ? true : false,
    },
    ssn: profile.ssn,
    phone: profile.phone,
    transferAmount: amount,
    saveData: profile.saveData && profile.saveData.save ? true : false,
    clientType: client,
  };
};

export const getDebitCashOutData = (profile, card, amount) => {
  const ownerAddress = {
    addressLine1: profile.street1,
    addressLine2: profile.street2,
    city: profile.city,
    postalCode: profile.postalCode,
    countryCode: profile.countryField.value,
    nationality: profile.nationality,
    region: profile.region || profile.stateField.value,
  };
  const birthDate = new Date(profile.birthDate);
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    birthDate: birthDate,
    ownerAddress: ownerAddress,
    cardDTO: {
      cardNumber: card.cardNumber.split(' ').join(''),
      expirationDate: card.expirationDate.replace('/', ''),
      ccv: card.ccv ? card.ccv.replace(' ', '') : null,
      saveData: card.saveData && card.saveData.save ? true : false,
    },
    transferAmount: amount,
    saveData: profile.saveData && profile.saveData.save ? true : false,
  };
};

export const idealContribute = (contributeData, eventId: number) => {
  return api({
    method: 'post',
    url: `me/events/${eventId}/payments/ideal`,
    data: contributeData,
  });
};

export const idealContributeWithoutAccount = (
  contributeData,
  eventId: number
) => {
  return apiGuestRequest({
    method: 'post',
    url: `events/${eventId}/payments/ideal`,
    data: contributeData,
  });
};

export const contribute = (contributeData, eventUuid: number) => {
  return api({
    method: 'post',
    url: `me/events/${eventUuid}/payments/card`,
    data: contributeData,
  });
};
export const contributeWithSavedCard = (contributeData, eventUuid: number) => {
  return api({
    method: 'post',
    url: `me/events/${eventUuid}/payments/cards/card`,
    data: contributeData,
  });
};
export const fetchCardPreRegistrationDataGuestService = (
  personalData
) => {
  const requestData = {
    firstName: personalData.firstName,
    lastName: personalData.lastName,
    email: personalData.email,
  };
  return apiGuestRequest({
    method: 'post',
    url: `/payments/card/registration`,
    data: requestData,
  });
};

export const fetchCardPreregistrationDataService = () => {
  return api({
    method: 'post',
    url: `me/payments/card/registration`,
  });
};
export const postCardDetailsToTokenizationServerService = (
  requestData,
  url
) => {
  return apiPublic({
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(requestData),
    url,
  });
};
export const contributeWithoutAccount = (contributeData, eventUuid: number) => {
  return apiGuestRequest({
    method: 'post',
    url: `events/${eventUuid}/payments/card`,
    data: contributeData,
  });
};

export const confirmCreditCardWithAccount = (eventId, transactionId) => {
  return api({
    method: 'patch',
    url: `me/events/${eventId}/payments/${transactionId}`,
  });
};

export const confirmCreditCardWithoutAccount = (
  userId,
  eventUuid,
  transactionId
) => {
  return apiGuestRequest({
    method: 'patch',
    url: `users/${userId}/events/${eventUuid}/payments/${transactionId}`,
  });
};

export const cashOutEmail = (eventUuId, cashOutData) => {
  return api({
    method: 'post',
    url: `me/events/${eventUuId}/payments/mail`,
    data: cashOutData,
  });
};

export const cashOutBank = (eventUuId, cashOutData) => {
  if(cashOutData.bankDTO) {
    if(cashOutData.bankDTO.country === "") {
      delete cashOutData.bankDTO["country"];
    }
  }
  return api({
    method: 'post',
    url: `me/events/${eventUuId}/payments/bank`,
    data: cashOutData,
  });
};

export const cashOutDebit = (eventUuId, cashOutData) => {
  return api({
    method: 'post',
    url: `me/events/${eventUuId}/payments/debit/cards`,
    data: cashOutData,
  });
};

export const getCreditCardData = () => {
  return api({ url: 'me/cards/details' });
};

export const deleteCreditCard = () => {
  return api({ method: 'delete', url: 'me/cards/details' });
};

export const getDebitCardData = () => {
  return api({ url: 'me/debit/cards/details' });
};

export const deleteDebitCard = () => {
  return api({ method: 'delete', url: 'me/debit/cards/details' });
};

export const isCustomEvent = (type: string): boolean => {
  const existingEvents = [
    'baby',
    'bachelor',
    'birthday',
    'holiday',
    'farewell',
    'vacation',
    'wedding',
    'house-warming',
  ];
  return typeof existingEvents.find(e => e === type) === 'undefined';
};

export const canRequestWishlist = (event, requestStatus, permissions) => {
  return (
    permissions.canRequestWishlist &&
    event.status === 'open' &&
    !isCustomEvent(event.occasion.code) &&
    !requestStatus &&
    permissions.roles.indexOf('owner') !== -1
  );
};

export const getMessages = (eventId, pageIndex, order) => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/messages?pageIndex=${pageIndex}&pageSize=5&sortOrder=${
      order ? order : ''
    }`,
  });
};

export const fillLastComment = (eventId, pageIndex, order) => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/messages?pageIndex=${pageIndex *
      5}&pageSize=1&sortOrder=${order ? order : ''}`,
  });
};
export const getInvitees = eventId => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/invitees`,
  });
};

export const getParticipants = eventId => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/users`,
  });
};

export const getPendingInvitees = eventId => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/invitees?status=SENDER_INVITE `,
  });
};

export const getContributors = eventId => {
  return api({
    method: 'get',
    url: `me/events/${eventId}/contributors`,
  });
};

export const postUserComment = (comment, eventId) => {
  const data = new FormData();

  if (comment.message) {
    data.append('message', comment.message);
  }

  if (comment.photo) {
    data.append('file', comment.photo[0]);
  }

  return api({
    method: 'post',
    url: `me/events/${eventId}/messages`,
    data: data,
  });
};

export const editUserComment = (comment, eventId, commentId) => {
  return api({
    method: 'patch',
    url: `me/events/${eventId}/messages/${commentId}`,
    data: { id: commentId, messageBody: comment.message },
  });
};
export const deleteUserComment = (eventId, commentId) => {
  return api({
    method: 'delete',
    url: `me/events/messages/${commentId}`,
  });
};

export const getEventWLGoals = eventId =>
  api({ url: `me/event/${eventId}/wishlist/goals` });

export const getPermissions = uuid =>
  api({ url: `/events/${uuid}/permissions` });

export const highlightLinks = message => {
  if (message) {
    let messLinks = '';
    const lines = message.split(/\r?\n/gim);
    lines.forEach((line, i, linesArr) => {
      const partsArr = line.split(' ');
      partsArr.forEach((word, j) => {
        if (urlRegExp(word)) {
          const href = word.substr(0, 4) !== 'http' ? `http://${word}` : word;
          messLinks += ` <a href="${href}" target="_blank" rel="noopener noreferrer"> ${word}</a>`;
        } else {
          messLinks += (j > 0 ? ' ' : '') + word;
        }
      });
      if (linesArr.length > 1) messLinks += '\r\n';
    });
    return messLinks;
  } else {
    return '';
  }
};
export const resetFileInput = () => {
  const fileInput = document.querySelector('input[type="file"]');
  if (fileInput) fileInput.value = '';
};

export const calculateCashoutAmount = (
  contributedAmount: number,
  cashOutFee: number
): number =>
  cashOutFee > 0
    ? parseFloat(
        (parseFloat(contributedAmount) * (1 - parseFloat(cashOutFee))).toFixed(
          2
        )
      )
    : parseFloat(contributedAmount);

export const parseCashOutFee = eventFee =>
  parseFloat(parseFloat(eventFee * 100).toFixed(1));

export const getEventPermissionsByUuid = uuid => {
  return api({ url: `me/events/${uuid}/permissions` });
};

export const checkCashOutPermissions = (permissions, history) => {
  if (!permissions) {
    history.push('/events');
    notif.error(i18n.t('notifications.auth.AccessDenied'));
  } else {
    return false;
  }
};

const generateUUID = () => {
  var d = new Date().getTime();
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const dataURLtoFile = dataurl => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n),
    filename = generateUUID() + '.' + mime.split('/')[1];

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
