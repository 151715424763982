import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import Maintenance from '../components/Maintenance';
import i18n from '../i18n';
import notif from '../modules/Notif/services';
import { externalNotif } from '../modules/Notif/services/externalNotif';
import * as mangoNotif from '../modules/Notif/services/mangoNotif';
import cognitoService from '../services/cognito';
import * as constants from '../services/constants';
import cookieUtils from '../services/cookies';
import tokenService from '../services/token';
import history from '../services/history';
const apiBase = constants.API_BASE;
const apiAcceptHeader = constants.API_ACCEPT_HEADER;
const apiBaseOauth = constants.OAUTH2_PROVIDER;

const onError = error => {
  if (typeof error.response === 'undefined') {
    if (error.toString().includes('Error: Network Error')) {
      // api might be under maintenance or cannot respond
      ReactDOM.render(
        <Maintenance />,
        document.getElementsByTagName('main')[0]
      );
    } else {
      notif.error(i18n.t('notifications.api.unavailable'));
    }
    return Promise.reject(error);
  }

  const { data } = error.response;
  const { type, detail, resultMessage, resultCode, invalidParams } = data;

  switch (error && error.response && error.response.status) {
    case 401:
      if (data && data.error === 'unauthorized_user') {
        notif.error(i18n.t('notifications.auth.AccessDenied'));
      }
      break;
    case 403:
      const { error_description } = data;
      if (data.error === 'access_denied') {
        if (
          error_description ===
          'Permissions cannot be evaluated. Cannot transfer funds'
        ) {
          notif.error(i18n.t('notifications.already-cashed-out'));
          break;
        }
        notif.error(i18n.t('notifications.auth.AccessDenied'));
      }
      break;
    case 422:
      // General payment errors
      if (type === 'consecutive.payments.timeout') {
        notif.error(i18n.t(`notifications.consecutive`));
        break;
      }
      if (type === 'payment.processing.error') {
        if (resultCode === '601105') {
          notif.error(i18n.t(`notifications.card.invalid`));
          break;
        }
        if (resultCode === '601114') {
          notif.error(i18n.t(`notifications.card.not-debit`));
          break;
        }
        if (resultCode === '601113') {
          notif.error(i18n.t(`notifications.validation.incorrect-age`));
          break;
        }
        if (resultCode === '601115' || resultCode === '601107') {
          notif.error(i18n.t(`notifications.card.incorrect-number`));
          break;
        }
        if (resultCode === '601109') {
          notif.error(i18n.t(`notifications.invalid-postal-code`));
          break;
        }
        if (resultCode === '601117') {
          notif.error(i18n.t(`notifications.invalid-country`));
          break;
        }
        if (resultCode === '02627') {
          notif.error(i18n.t('notifications.card.invalid-security-code'));
          break;
        }
        if (resultCode === '101105') {
          notif.error(i18n.t('notifications.card.expired'));
          break;
        }
        if (resultCode === '601103') {
          notif.error(i18n.t('notifications.processing-error'));
          break;
        }
        if (resultCode === '26202') {
          notif.error(i18n.t('notifications.validation.iban-invalid'));
          break;
        }
        if (resultCode === '601112') {
          notif.error(
            i18n.t(`notifications.validation.routing-number-invalid`)
          );
          break;
        }
        notif.error(i18n.t(`notifications.invalid`));
        break;
      }
      if (type === 'card.not.active') {
        notif.error(i18n.t(`notifications.not-active`));
        break;
      }
      // Mango payment errors
      if (type === 'mango.kyc.insufficient') {
        mangoNotif.insufficient();
        break;
      }
      if (type === 'psp.technical.error') {
        notif.error(i18n.t('notifications.card.psp-technical'));
        break;
      }
      if (type === 'validation.error') {
        if (invalidParams && invalidParams[0].name === 'amount') {
          notif.error(
            i18n.t('notifications.validation.max-amount', {
              currency: i18n.t('currency'),
            })
          );
          break;
        }
        if (
          invalidParams &&
          invalidParams[0].reason === 'The date is in the past'
        ) {
          notif.error(i18n.t('notifications.validation.date-past'));
          break;
        }
        if (invalidParams && invalidParams[0].reason === 'iban.invalid') {
          notif.error(i18n.t('notifications.validation.iban-invalid'));
          break;
        }
        if (
          invalidParams &&
          invalidParams[0].name === 'creditCardDTO.cardNumber'
        ) {
          notif.error(i18n.t('notifications.validation.card-number-invalid'));
          break;
        }
        //cashout debit card

        if (
          invalidParams &&
          invalidParams[0].reason ===
            'The amount must not be higher than $3000.'
        ) {
          notif.error(i18n.t('notifications.more-than-3000'));
          break;
        }
        notif.error(i18n.t('notifications.validation.invalid'));
      }
      //ideal transaction cancelled
      if (type === 'transaction.cancelled') {
        notif.error(i18n.t(`notifications.payment.cancelled`));
        break;
      }
      if (type === 'transaction.refused') {
        if (resultCode === '101199') {
          notif.error(i18n.t('notifications.payment.refused'));
          break;
        }
      }
      if (type === 'payment.session.expire') {
        if (resultCode === '001034') {
          notif.error(i18n.t('notifications.payment.session.expire'));
          break;
        }
      }
      if (type !== 'validation.error' && detail && detail.length) {
        switch (detail) {
          case 'A similar wishlist already exists':
            notif.error(i18n.t('notifications.wishlist.duplicate-error'));
            break;
          default:
            notif.error(i18n.t(`notifications.${detail}`));
            break;
        }
      }
      if (
        type !== 'validation.error' &&
        resultMessage &&
        resultMessage.length
      ) {
        // make sure there are no . in the result message, since that will break
        // the translation
        notif.error(i18n.t(`notifications.${resultMessage.replace('.', '')}`));
      }
      if (type === 'external_account_change_not_allowed') {
        notif.error(i18n.t('notifications.account-change-not-allowed'));
      }
      break;

    case 500:
      notif.error(i18n.t('notifications.api.exception'));
      break;

    case 502:
      notif.error(i18n.t('notifications.api.payment-error'));
      break;

    case 503:
      notif.error(i18n.t('notifications.api.unavailable'));
      ReactDOM.render(
        <Maintenance />,
        document.getElementsByTagName('main')[0]
      );
      break;

    case 504:
      notif.error(i18n.t('notifications.api.unavailable'));
      ReactDOM.render(
        <Maintenance />,
        document.getElementsByTagName('main')[0]
      );
      break;

    case 505:
      externalNotif();
      break;

    default:
      break;
  }

  return Promise.reject(error);
};

export const apiRequest = async options => {
  const token = await cognitoService.getTokens().catch(e => {
    // error receveing token, redirect to login
    if (e.name === 'NotAuthorizedException') {
      history.push('/logout/login'); //logout to clear the state, redirect to login
      throw e;
    }
  });
  if (!token) {
    return false;
  }
  const axiosInstance = axios.create({
    baseURL: apiBase,
    headers: {
      Accept: apiAcceptHeader,
      Authorization: `Bearer ${token.id_token}`,
    },
  });

  return axiosInstance(options)
    .then(response => response.data)
    .catch(onError);
};

export const apiGuestRequest = async (options, count = 0) => {
  const token = await tokenService();
  const self = this;
  const axiosInstance = axios.create({
    baseURL: apiBase,
    headers: {
      Accept: apiAcceptHeader,
      Authorization: `Bearer ${token}`,
    },
  });
  return axiosInstance(options)
    .then(response => response.data)
    .catch(e => {
      if (e && e.response && e.response.status && e.response.status === 401) {
        if (count < 3) {
          const next = count + 1;
          cookieUtils.removeCookie('guestToken');
          //token invalid, retry with new token
          return apiGuestRequest.call(self, options, next);
        } else {
          onError(e);
          throw e;
        }
      } else {
        onError(e);
        throw e;
      }
    });
};

export const apiPublic = options => {
  const axiosInstance = axios.create({
    baseURL: apiBase,
    headers: {
      Accept: apiAcceptHeader,
    },
  });

  return axiosInstance(options)
    .then(response => response.data)
    .catch(onError);
};
export const apiHealthCheck = options => {
  const req = axios.create({
    baseURL: `${apiBase}health`,
    headers: {
      Accept: apiAcceptHeader,
    },
    timeout: 9000,
  });
  return req(options)
    .then(response => response.data)
    .catch(e => e);
};

export const apiFederate = options => {
  const axiosInstance = axios.create({
    baseURL: apiBaseOauth,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return axiosInstance(options)
    .then(response => response.data)
    .catch(onError);
};

export const apiExternal = options => {
  const axiosInstance = axios.create({ timeout: 20000 });

  return axiosInstance(options)
    .then(response => response.data)
    .catch(e => {
      throw e;
    });
};

export default apiRequest;
